import { render, staticRenderFns } from "./MyFeedbacks.vue?vue&type=template&id=a16e70a8&scoped=true&"
import script from "./MyFeedbacks.vue?vue&type=script&lang=js&"
export * from "./MyFeedbacks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a16e70a8",
  null
  
)

export default component.exports